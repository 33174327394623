import logo from './logoani1.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" width="20%" hight="20%"/>
        <p>
          Tatsuya NOGUCHI
        </p>
        <a
          className="App-link"
          href="https://tatsuya-noguchi.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          AROUND DIGITAL IP Business marketing
        </a>
      </header>
    </div>
  );
}

export default App;
